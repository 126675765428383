import React from 'react'

export default class CheckIcon extends React.Component{
    render(){
        return (
            <div className="ciao">
                {
                this.props.status === 200
                ? <div><img src="./img/affirmative.png" alt=""></img></div>
                : <div><img src="./img/negative.png" alt=""></img></div>
                }
            </div>
        )
    }
}