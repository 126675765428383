import React from 'react';
import CheckIcon from './CheckIcon';
import './CheckIcon.css'

export default class TableListItem extends React.Component{
    render(){
        return(
            <tbody>
                <tr>
                    <td><CheckIcon status = {this.props.status}/></td>
                    <td>{this.props.name}</td>
                    <td>{this.props.url}</td>
                    <td>{this.props.cdateStr}</td>
                </tr>
            </tbody>
        )
    }
}