import React from 'react';
import axios from 'axios';
import TableListItem from './TableListItem';

export default class App extends React.Component{
  constructor(props){
    super(props)
    this.state={
      isLoading: true,
      gifURL: '',
      services: null
    }
    this.getSites = this.getSites.bind(this)
  }

  componentDidMount(){
      this.getSites();
  }

  async getSites() {
      try{
        const services = await axios.get('https://europe-west6-serviceshealth.cloudfunctions.net/SH-getLatestStatus')
         this.setState({
             services: services.data.sites
         })
      }
      catch(err){
        console.log(err.ToString())
      }
    }

    render() {
        const {services} = this.state
        console.log(this.state.services)
    return (<div className="container-fluid">
        <h1 className="text-center"><img src="./img/header-logo.png" alt=""></img> Services Health</h1>
        <div className="container">
        <div className="shadow p-3 mb-5 bg-white rounded">
            <h3>Current Status</h3>
            <table className="table table-striped table-bordered" width="50%">
            <thead>
                <tr>
                <th className="th-sm">Status</th>
                <th className="th-sm">Nome</th>
                <th className="th-sm">Url</th>
                <th className="th-sm">Data</th>
                </tr>
            </thead>
            {services && services.map((item)=>(
            <TableListItem status = {item.status} name = {item.name} url = {item.url} cdateStr = {item.cdateStr}/>
            ))}
            </table>
        </div>
        </div>
    </div>
    )
    }
};